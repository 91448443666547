<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style=" overflow: auto">
    
      <!-- 分类搜索 -->
      <el-row >
            <el-col :span="3.3" class="mr15" style="  float:right ;">
        <div class="grid-content bg-purple-dark">
                
            <el-button class="el-buttonr" size="mini" v-if="this.isMerchant"  type="text" @click="onclassifyVisible()">添加商品</el-button>
          </div>
          </el-col>
     
        <el-col class="mr15" :span="3.3" v-if="!this.isMerchant">
          <el-input size="mini" v-model="queryInfo.shopName" @input="getGoodsshopName" placeholder="所属门店"   >
            <el-button slot="append" icon="el-icon-search"/>
          </el-input>
        </el-col>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" v-model="queryInfo.keyword" @input="getGoodsListkow" placeholder="商品名称"   >
            <el-button slot="append" icon="el-icon-search"/>
          </el-input>
        </el-col>
         <!-- 城市搜索 -->
          <el-col class="mr15" :span="3.3" v-if="!this.isMerchant" >
         <v-distpicker
              @province="onprovince"
                @city="oncity"
                class="v-distpicker"
                :province="queryInfo.province"
                :city="queryInfo.city"
                hide-area
              ></v-distpicker>
              </el-col>
       
        <el-col :span="3.3"  v-if="!this.isMerchant">
          <el-select size="mini"  v-model="queryInfo.status" clearable placeholder="商品一级分类" style=" width: 150px;"  @change="getSelectGoodsListStatus"  class="mr15">
            <el-option
              v-for="(item, index) in commodityStatus"
              :key="index"
             :label="item.name"
             :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
         <el-col :span="3.3"  v-if="!this.isMerchant">
          <el-select size="mini" v-model="queryInfo.goodsSubcategoryId" clearable placeholder="商品二级分类" style=" width: 150px;"  @change="getGoodsList"  class="mr15">
            <el-option
              v-for="(item, index) in categorydate"
              :key="index"
               :label="item.name"
             :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
         <el-col :span="3.3">
          <el-select
            size="mini"
            style=" width: 150px;"
            v-model="queryInfo.goodsStatus"
            clearable
            placeholder="商品状态"
            @change="goodsStatus"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in merchantStatusList"
              :key="index"
             :label="item.label"
             :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      
      <!--商品数据展示区域-->
      <el-table
        :data="tableData"
        style="width: 100%"
      
        ref="singleTable"
        highlight-current-row
        border
        v-loading="loading"
        row-key="id"
        stripe
        :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
      >
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="id" label="商品ID" width="100" show-overflow-tooltip/>

           <el-table-column prop="businessName" label="店铺名称" width="200" show-overflow-tooltip/>
     
        <el-table-column prop="goodsName" label="商品名称" width="200" show-overflow-tooltip/>
        <el-table-column prop="previewUrl" width="100" label="商品列表图" >
          <template slot-scope="scope">
            <img
            @click="dialogimgs(scope.row.previewUrl)"
            
              :src="scope.row.previewUrl"
              alt=""
             width="80px" height="50px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="scribePrice" label="商品状态" width="100" show-overflow-tooltip>
    <template slot-scope="scope">
    <span v-if="scope.row.goodsStatus==0">上架中</span>
      <span v-else>已下架</span>
    </template>
       
        </el-table-column>
        <el-table-column prop="goodsPrice" label="成交价格" width="100" show-overflow-tooltip/>
         <el-table-column prop="stockNum" label="库存" width="100" show-overflow-tooltip/>
         <el-table-column prop="monthSales" label="总销量" width="100" show-overflow-tooltip/>
         <el-table-column prop="goodsEndingTime" label="下架时间" width="200" show-overflow-tooltip>
         <!-- <template slot-scope="scope">
   <span> {{time(scope.row.goodsEndingTime)}}</span>
    </template> -->
         </el-table-column>
          <el-table-column prop="addressJson.city" label="城市" width="100" show-overflow-tooltip/>
   <el-table-column prop="scribePrice" label="是否支持配送" width="100" show-overflow-tooltip>
    <template slot-scope="scope">
    <span v-if="scope.row.goodsType==1||scope.row.goodsType==6">支持</span>
      <span v-else>不支持</span>
    </template>
       </el-table-column>
    <!-- <el-table-column prop=" stockNum" label="审核状态" width="100" show-overflow-tooltip>
      <template slot-scope="scope">
    <span v-if="scope.row.examineStatus==0">待审核</span>
    <span v-else-if="scope.row.examineStatus==1">审核成功 </span>
      <span v-else>审核失败</span>
    </template>
    </el-table-column> -->
        <el-table-column label="操作" min-width="200" fixed="right">
        <template slot-scope="scope">

          <el-button @click="searchGoodsById(scope.row.id,scope.row.addressJson.latitude,scope.row.addressJson.longitude,scope.row.businessId)" size="mini" type="text" >编辑</el-button>
          <el-button @click="specificationVisible(scope.row.id)" size="mini" type="text">规格</el-button>
             <el-button v-if="scope.row.goodsStatus==1" @click="updateGoodStatust(scope.row.id,0)" size="mini" type="text">上架</el-button>
              <el-button v-else @click="updateGoodStatust(scope.row.id,1)" size="mini" type="text">下架</el-button>
           <el-popconfirm @confirm="deleter(scope.row.id)" style="padding-left: 20px;" title="确定删除此内容吗？">
          <el-button  slot="reference" size="mini" type="text">删除</el-button>
          </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10,]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 列表大图展示 -->
     <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
              <img width="100%" :src="dialogimgsrc" alt="" />
            </el-dialog>
    </el-card>
  <!-- 编辑商品区域 -->
    <el-dialog :append-to-body="true" :visible.sync="addmerchandise" fullscreen   title="编辑商品">
      <!-- <el-row style="display: flex; justify-content: center; margin: -25px 0 20px">
        <el-button size="mini" type="goon"  :id="setType == 0 ? 'butto' : ''"  @click="setType = 0" >基础设置</el-button>
        <el-button  size="mini"  type="goon"  :id="setType == 1 ? 'butto' : ''"  @click="setType = 1" >价格设置</el-button>
        <el-button size="mini" type="goon"  :id="setType == 2 ? 'butto' : ''"  @click="setType = 2" >详情设置</el-button>
      </el-row> -->
      <!-- 基础设置 -->
      <!-- <div v-if="setType === 0"> -->
        <el-form :model="goodsdate" style="margin-left:20px ;">
          <!-- <el-form-item label="商品卖方" required >
            <el-col class="mr15" :span="3.3">
              <el-radio v-model="radio" label="1">卖家</el-radio>
              <el-radio v-model="radio" label="2">富航生活平台</el-radio>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <el-input size="mini" placeholder="会员手机号"   ></el-input>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <span>123</span>
            </el-col>
          </el-form-item> -->
         <el-form-item label="商品名称" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="goodsdate.goodsName" ></el-input>
              </el-col>
            </el-form-item>
       <el-form-item label="商品列表图"  required>
               <el-upload
               v-if="addmerchandise"
              action=""
              :limit="1"
              :http-request="upGoodsRead"
              list-type="picture-card"
              :on-preview="revampGoodsRead"
           :on-remove="handleRemoveGoodsRead"
           :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="revampGoodsReadstust" :append-to-body="true">
              <img width="100%" :src="revampGoodsReadUrl" alt="" />
            </el-dialog>
                 <template>
              <img   :src="previewUrl" alt="" @click="dialogimgs(goodsdate.previewUrl)" width="150px" style="margin: 15px 15px 0px 90px" >
            </template>
          </el-form-item>
            <el-form-item label="商品橱窗图"  required>
               <el-upload
               v-if="addmerchandise"
              action=""
              :limit="8"
              :http-request="upslideshow"
              list-type="picture-card"
              :on-preview="slideshowReversesideofidcard"
           :on-remove="slideshowPictureCardRemoveInPhotoList"
            :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
           
            <el-dialog :visible.sync="slideshowVisiblePhoto" :append-to-body="true">
              <img width="100%" :src="slideshowInPhotoUrl" alt="" />
            </el-dialog>
            
             <template >
              <div style="margin: 0px 0px 0px 85px">
              <img   v-for="(i,n) in ImgsJson" :key="n"  :src="i" alt="" @click="dialogimgs(i)" width="150px" height="100px" style="margin: 15px 15px 0px 10px" >
            </div>
            </template>
            
          </el-form-item>
             <el-form-item class="mdr5" label="商品橱窗视频" prop="Video">
    <el-upload  action=""
             
              :http-request="handleVideoSuccess"
              list-type="picture-card"
           :before-upload="beforeUploadVideo">
         <i class="el-icon-plus"></i>
    </el-upload>
    <video style="position: absolute;top: 0px; left: 250px;width: 200px;height: 150px" v-if="videoForm.Video !='' && videoFlag == false" :src="videoForm.Video" autoplay="autoplay" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
<i v-if="videoForm.Video !='' && videoFlag == false" @click="onvideoForm" style="position: absolute;top: -5px; left: 445px;" class="el-icon-error"></i>
</el-form-item>
 <el-form-item label="商品视频封面"  >
               <el-upload
               v-if="addmerchandise"
              action=""
              :limit="1"
              :http-request="upGoodsReadvideo"
              list-type="picture-card"
              :on-preview="revampGoodsReadvideo"
           :on-remove="handleRemoveGoodsReadvideo"
         :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-dialog :visible.sync="revampGoodsReadstustvideo" :append-to-body="true">
              <img width="100%" :src="revampGoodsReadUrlvideo" alt="" />
            </el-dialog>
                 <template>
              <img   :src="addvideoPreviewImgUrl" alt="" @click="dialogimgs(addvideoPreviewImgUrl)" width="150px" style="margin: 15px 15px 0px 95px" >
              <i v-if="addvideoPreviewImgUrl"  @click="onvideoPreviewImgUrl" style="position: absolute;top: 155px; left: 240px;" class="el-icon-error"></i>
            </template>
          </el-form-item>
            <el-form-item style="width: 300px" label="商品分类" required>
             <el-tooltip class="item"  effect="dark" content="提示改为：要先在“店铺设置-商品分类管理”里面设置好门店内销售的商品类别，然后才在这里给新上架的商品从中选择一种分类。" placement="top-start">
        <i class="el-icon-question"></i>
          </el-tooltip>
              <el-select
              style="width: 110px"
                size="mini"
                v-model="goodsdate.businessCategoryId"
                clearable
                placeholder="商品分类"
               
                class="mr15"
              >
                <el-option
                  v-for="(item, index) in searchBusinessCategoryList"
                  :key="index"
                  :label="item.categoryName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
       
          <el-row style="padding-top:15px ;">
         
              <el-form-item style="width: 430px" label="售卖周期" required v-if="addmerchandise">
                <el-date-picker
                 v-model="handleDate"
                  type="daterange"
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['10:00:00', '10:00:00']"
                  @change="goodsTime()"
                >
                </el-date-picker>
              </el-form-item>
           
             <el-form-item label="购买有效天数" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="goodsdate.effectDays" ></el-input>
              </el-col>
            </el-form-item>
          
             </el-row>
          <!-- <el-row >
               <el-col   class="mr15" style="margin: 20px 0; display: flex; justify-content: left"  :span="24">
              <div style="margin: 0 20px 0 0">
                <el-button size="small" type="primary" @click="ongoodsDeta">添加商品描述</el-button>
              </div>
              <div>
                <div style=" display: flex; justify-content: left; margin: 0 0 10px 0;" v-for="(i,n) in goodsDetailsJsons" :key="n">
                 <i style="margin: 7px 5px 0 -10px; color: red;" class="el-icon-error" @click=" remogoodsDetailsJson(n)" /> <el-input size="mini"  placeholder="请输入内容"  v-model="i.t1"  ></el-input>
                </div>
              </div>
            </el-col>
               </el-row> -->
           <el-form-item label="默认规格:"   required>  
            <el-tooltip class="item" style="float: left; margin-top: 15px;"  effect="dark" content=" “默认规格”提示：设置商品的规格，价格，收入，库存，限购。步骤：（1）设置价格，收入，库存，限购（2）点击添加规格，设置规格名称（3）点击页面空白处，设置成功。
               <br/>举例：套餐一对应市场价1000元，库存100件，限购2件。" placement="top-start">
        <i class="el-icon-question"></i>
          </el-tooltip>
             <div  style="float: left;" class="mr15">
          <span class="mr15">商品价格</span>
                <el-input   v-model="goodsdate.goodsPrice" style="width: 100px" size="mini" ></el-input>
            </div>
          <div  style="float: left;" class="mr15">
          <span class="mr15">商品划线价</span> 
           <el-input   v-model="goodsdate.scribePrice" style="width: 100px" size="mini"   ></el-input>
           </div>
            <div  style="float: left;" class="mr15">
            <span class="mr15">库存量</span>
                <el-input   v-model="goodsdate.stockNum" style="width: 100px" size="mini"   ></el-input>
            </div>
          </el-form-item>
              
           
          
               <div style=" display: flex;width: 80%">  
                 <el-form-item label="限购" style="width: 270px" >
                <el-input   v-model="goodsdate.limitNum" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
             <el-form-item label="下单可获得积分" style="width: 270px" >
                <el-input   v-model="goodsdate.rewardIntegral" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
             

             
               <el-form-item v-if="goodsdate.goodsType!==4&&goodsdate.goodsType!==5&&logisticsType!==7" label="商品重量" style="width: 270px" >
                <el-input   v-model="goodsdate.goodsWeight" style="width: 100px" size="mini"   ></el-input>
                  /g
            </el-form-item>     
         <el-form-item v-if="goodsdate.goodsType==1|| goodsdate.goodsType==3||logisticsType==6" label="打包费"  style="width: 270px" >
                <el-input v-model="goodsdate.packingFee" style="width: 100px" size="mini"   ></el-input>
                元/份
            </el-form-item>
           <el-form-item v-if="goodsdate.goodsType==1||logisticsType==6" label="商家配送费" style="width: 270px" required>
                <el-input   v-model="goodsdate.deliveryFee" style="width: 100px" size="mini"   ></el-input>
            元/公里
            </el-form-item>
              
            </div>
               <el-form-item   label="商品物流:" required>
              <div v-if="logisticsType==6" >
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether1">配送</el-checkbox>
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether2">自提</el-checkbox>
              </div>
              <div v-else-if="logisticsType==7" >
              <el-checkbox v-model="goodsTypewhether.goodsTypewhether1">到店消费</el-checkbox>
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether2">上门服务</el-checkbox>
          </div>
        <span v-else-if="goodsdate.goodsType==1">配送</span>
        <span v-else-if="goodsdate.goodsType==2">快递</span>
        <span v-else-if="goodsdate.goodsType==3">自提</span>
        <span v-else-if="goodsdate.goodsType==4">到店消费</span>
        <span v-else-if="goodsdate.goodsType==5">上门服务</span>
            </el-form-item>
                <el-form-item label="分享信息"  >
               <el-col :span="5">
          <el-input  v-model="goodsdate.shareJson" size="mini" ></el-input>
          </el-col>
        </el-form-item>
             <el-form-item label="使用规则"   required>
            <Editor v-if="addmerchandise" :value="getVal" style="margin-left:80px ;" v-model="getVal" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'"/>
        </el-form-item>
             <el-form-item label="商品详情"  required>
       <editor v-if="addmerchandise" style="margin-left:80px ;" :value="content" v-model="contentc" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'"  />
         </el-form-item>
  </el-form>
      
      <span slot="footer" class="dialog-footer">
      <el-button @click="addmerchandise=false" >取消</el-button>
        <el-button :loading="onaddloding" @click="onaddmerchandiseDate" type="primary">保存</el-button>
      </span>
    
    </el-dialog>
    <!-- 规格设置弹窗 -->
     <el-dialog :append-to-body="true" :visible.sync="specification"    title="商品规格">
 
      <el-button size="mini" type="primary" @click="addspecification = true">添加规格</el-button>
       <el-table
        :data="specificationDate"
        style="width: 100%"
        ref="singleTable"
        highlight-current-row
     
        row-key="id"
        stripe
      >
        <el-table-column prop="specificationName" label="规格名称" width="100" show-overflow-tooltip/>
        <el-table-column prop="dealPrice" label="成交价" width="150" show-overflow-tooltip/>
        <el-table-column prop="scribePrice" label="划线价" width="150" show-overflow-tooltip/>
        <el-table-column prop="originalPrice" label="原价" width="150" show-overflow-tooltip/>
        <el-table-column prop="wechatPrice" label="小程序价格" width="150" show-overflow-tooltip/>
        <el-table-column prop="appPrice" label="app价格" width="150" show-overflow-tooltip/>
        <el-table-column prop="stockNum" label="库存量" width="100" show-overflow-tooltip/>
        <el-table-column label="操作" min-width="200" fixed="right">
        <template slot-scope="scope">
          <el-button @click="redactspecification(scope.row)"  :disabled="scope.row.specificationName=='默认'" size="mini" type="text">编辑</el-button>
          <el-button  slot="reference" :disabled="scope.row.specificationName=='默认'"   @click="delGoodsSpecifi(scope.row.id,scope.row.goodsId)" size="mini" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
     <!-- 添加规格 -->
         <el-dialog :append-to-body="true" :visible.sync="addspecification" width=" 30%" >
          <el-form  label-width="80px">
          <el-form-item label="规格名称" required>
              <el-input size="mini"  v-model="addspecificationDate.specificationName" ></el-input>
          </el-form-item>
          <el-form-item label="成交价" required>
              <el-input size="mini"  v-model="addspecificationDate.dealPrice" ></el-input>
          </el-form-item>
             <el-form-item label="划线价" required>
              <el-input size="mini"  v-model="addspecificationDate.scribePrice" ></el-input>
          </el-form-item>
             <el-form-item label="库存量" required>
              <el-input size="mini"  v-model="addspecificationDate.stockNum" ></el-input>
          </el-form-item>
        </el-form>
         <span slot="footer">
          <el-button @click="innerVisibler=false">取 消</el-button>
          <el-button type="primary" @click="addspecificationDater">确 定</el-button>
        </span>
         </el-dialog>
         <!-- 编辑规格 -->
           <el-dialog :append-to-body="true" :visible.sync="redactpecification" width=" 30%" >
          <el-form  label-width="80px">
          <el-form-item label="规格名称" required>
              <el-input size="mini"  v-model="redactspecificationDate.specificationName" ></el-input>
          </el-form-item>
          <el-form-item label="成交价" required>
              <el-input size="mini"  v-model="redactspecificationDate.dealPrice" ></el-input>
          </el-form-item>
             <el-form-item label="划线价" required>
              <el-input size="mini"  v-model="redactspecificationDate.scribePrice" ></el-input>
          </el-form-item>
             <el-form-item label="库存量" required>
              <el-input size="mini"  v-model="redactspecificationDate.stockNum" ></el-input>
          </el-form-item>
        </el-form>
         <span slot="footer">
          <el-button @click="redactpecification=false">取 消</el-button>
          <el-button type="primary" @click="redactspecificationDater">确 定</el-button>
        </span>
         </el-dialog>
     </el-dialog>

<!-- 上架商品 -->

        <el-dialog :append-to-body="true" :visible.sync="addmerchandiset" fullscreen   title="上架商品">
      <!-- <el-row style="display: flex; justify-content: center; margin: -25px 0 20px">
        <el-button size="mini" type="goon"  :id="setType == 0 ? 'butto' : ''"  @click="setType = 0" >基础设置</el-button>
        <el-button  size="mini"  type="goon"  :id="setType == 1 ? 'butto' : ''"  @click="setType = 1" >价格设置</el-button>
        <el-button size="mini" type="goon"  :id="setType == 2 ? 'butto' : ''"  @click="setType = 2" >详情设置</el-button>
      </el-row> -->
      <!-- 基础设置 -->
      <!-- <div v-if="setType === 0"> -->
        <el-form :model="addmerchandiseDate" style="margin-left:20px ;">
          <!-- <el-form-item label="商品卖方" required >
            <el-col class="mr15" :span="3.3">
              <el-radio v-model="radio" label="1">卖家</el-radio>
              <el-radio v-model="radio" label="2">富航生活平台</el-radio>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <el-input size="mini" placeholder="会员手机号"   ></el-input>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <span>123</span>
            </el-col>
          </el-form-item> -->
         <el-form-item label="商品名称" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="addmerchandiseDate.goodsName" ></el-input>
              </el-col>
            </el-form-item>
       <el-form-item label="商品列表图"  required>
               <el-upload
               v-if="addmerchandiset"
              action=""
              :limit="1"
              :http-request="upaddGoodsRead"
              list-type="picture-card"
              :on-preview="addrevampGoodsRead"
           :on-remove="addhandleRemoveGoodsRead"
           :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="revampGoodsReadstust" :append-to-body="true">
              <img width="100%" :src="revampGoodsReadUrl" alt="" />
            </el-dialog>
          </el-form-item>
         
            <el-form-item label="商品橱窗图"  required>
               <el-upload
               v-if="addmerchandiset"
              action=""
              :limit="8"
              :http-request="upaddslideshow"
              list-type="picture-card"
              :on-preview="addslideshowReversesideofidcard"
           :on-remove="addslideshowPictureCardRemoveInPhotoList"
            :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="addslideshowVisiblePhoto" :append-to-body="true">
              <img width="100%" :src="addslideshowInPhotoUrl" alt="" />
            </el-dialog>
          </el-form-item>
     <el-form-item label="商品橱窗视频" prop="Video">
     
    <el-upload class="mdr5"  action=""
           
              :http-request="addhandleVideoSuccess"
              list-type="picture-card"
           :before-upload="beforeUploadVideo">
         <i class="el-icon-plus"></i>
         
    </el-upload>
    <video style="position: absolute;top: 0px; left: 250px;width: 200px;height: 150px;" v-if="addvideoForm.Video" :src="addvideoForm.Video" autoplay="autoplay" class="avatar" controls="controls"></video>
      <i v-if="addvideoForm.Video"  @click="onaddvideoForm" style="position: absolute;top: -5px; left: 445px;" class="el-icon-error"></i>
</el-form-item>
 <el-form-item label="商品视频封面"  >
               <el-upload
               v-if="addmerchandiset"
              action=""
              :limit="8"
              :http-request="upaddslidevideo"
              list-type="picture-card"
              :on-preview="addslideshowReversesideofidcardvideo"
           :on-remove="addslideshowPictureCardRemoveInPhotoListvideo"
           :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="addslideshowInPhotoUrlvideo" :append-to-body="true">
              <img width="100%" :src="addslideshowVisiblePhotovideo" alt="" />
            </el-dialog>
          </el-form-item>
            <el-form-item style="width: 300px" label="商品分类" required>
              <el-tooltip class="item"  effect="dark" content="提示改为：要先在“店铺设置-商品分类管理”里面设置好门店内销售的商品类别，然后才在这里给新上架的商品从中选择一种分类。" placement="top-start">
        <i class="el-icon-question"></i>
          </el-tooltip>
              <el-select
              style="width: 110px"
                size="mini"
                v-model="addmerchandiseDate.businessCategoryId"
                clearable
                placeholder="商品分类"
              
                class="mr15"
              >
                <el-option
                  v-for="(item, index) in searchBusinessCategoryListtion"
                  :key="index"
                  :label="item.categoryName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
       
          <el-row style="padding-top:15px ;">
         
              <el-form-item style="width: 430px" label="售卖周期" required>
                <el-date-picker
                 v-model="handleDatet"
                  type="daterange"
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['10:00:00', '10:00:00']"
                  @change="goodsTimer()"
                >
                </el-date-picker>
              </el-form-item>
           
             <el-form-item label="购买有效天数" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="addmerchandiseDate.effectDays" ></el-input>
              </el-col>
            </el-form-item>
          
             </el-row>
          
               <!-- <el-col   class="mr15" style="margin: 20px 0; display: flex; justify-content: left"  :span="24">
              <div style="margin: 0 20px 0 0">
                <el-button size="small" type="primary" @click="onaddgoodsDeta">添加商品描述</el-button>
              </div>
              <div>
                <div style=" display: flex; justify-content: left; margin: 0 0 10px 0;" v-for="(i,n) in addgoodsDetailsJsons" :key="n">
                 <i style="margin: 7px 5px 0 -10px; color: red;" class="el-icon-error" @click=" addremogoodsDetailsJson(n)" /> <el-input size="mini"  placeholder="请输入内容"  v-model="i.t1"  ></el-input>
                </div>
              </div>
            </el-col> -->
          
          <div >  
           <el-form-item label="默认规格："  required>
            <el-tooltip class="item" style="float: left; margin-top: 15px;"  effect="dark" content=" “默认规格”提示：设置商品的规格，价格，收入，库存，限购。步骤：（1）设置价格，收入，库存，限购（2）点击添加规格，设置规格名称（3）点击页面空白处，设置成功。
               <br/>举例：套餐一对应市场价1000元，库存100件，限购2件。" placement="top-start">
        <i class="el-icon-question"></i>
          </el-tooltip>
           <div style="float: left;" class="mr15">
               <span class="mr15" >
               商品价格
               </span>
                <el-input   v-model="addmerchandiseDate.goodsPrice" style="width: 100px" size="mini"   ></el-input>
                </div>
                <div style="float: left;" class="mr15">
                <span >
                商品划线价
                </span>
                <el-input   v-model="addmerchandiseDate.scribePrice" style="width: 100px" size="mini"   ></el-input>
                </div>
                <div style="float: left;" class="mr15">
                <span >
                 库存量
                 </span>
                <el-input   v-model="addmerchandiseDate.stockNum" style="width: 100px" size="mini"   ></el-input>
                </div>
     </el-form-item>
     
             </div>
        <div v-if="distributionType!==4&&distributionType!==5&&distributionType!==7" style=" display: flex;width: 80%">  
                <el-form-item label="限购" style="width: 160px" >
                <el-input   v-model="addmerchandiseDate.limitNum" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
             <el-form-item label="下单可获得积分" style="width: 250px" >
                <el-input   v-model="addmerchandiseDate.rewardIntegral" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
               <el-form-item label="商品重量" style="width: 250px" >
                <el-input v-model="addmerchandiseDate.goodsWeight" style="width: 100px" size="mini"   ></el-input>
                  /g
            </el-form-item>
            
         <el-form-item v-if="distributionType==1|| distributionType==3||distributionType==6" label="打包费"  style="width: 250px" >
                <el-input v-model="addmerchandiseDate.packingFee" style="width: 100px" size="mini"   ></el-input>
                元/份
            </el-form-item>
        
             <el-form-item v-if="distributionType==1||distributionType==6"  label="商家配送费" style="width: 250px">
                <el-input   v-model="addmerchandiseDate.deliveryFee" style="width: 100px" size="mini"   ></el-input>
            元/公里
            </el-form-item>
            </div>      
        <el-form-item label="商品物流:" required>
        <span v-if="distributionType==1">配送</span>
        <span v-else-if="distributionType==2">快递</span>
        <span v-else-if="distributionType==3">自提</span>
        <span v-else-if="distributionType==4">到店消费</span>
        <span v-else-if="distributionType==5">上门服务</span>
         <div v-else-if="distributionType==6" >
         <el-checkbox v-model="addgoodsTypewhether.goodsTypewhether1">配送</el-checkbox>
         <el-checkbox v-model="addgoodsTypewhether.goodsTypewhether2">自提</el-checkbox>
              </div>
              <div v-else-if="distributionType==7" >
              <el-checkbox v-model="addgoodsTypewhether.goodsTypewhether1">到店消费</el-checkbox>
         <el-checkbox v-model="addgoodsTypewhether.goodsTypewhether2">上门服务</el-checkbox>
         </div>
        <!-- <span v-else-if="addmerchandiseDate.goodsType==6"> 配送与自提</span>
        <span v-else-if="addmerchandiseDate.goodsType==7"> 到店与上门</span> -->
            </el-form-item>
                <el-form-item label="分享信息"  >
               <el-col :span="5">
          <el-input  v-model="addmerchandiseDate.shareJson" size="mini" ></el-input>
          </el-col>
           
        </el-form-item>
        
               <el-form-item label="使用规则"  required>
       <Editor v-if="addmerchandiset"  :value="getVal" style="margin-left:80px ;" v-model="getValr" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'" />
        </el-form-item>
             <el-form-item label="商品详情"  required>
            
         <Editor v-if="addmerchandiset" :value="content" style="margin-left:80px ;" v-model="contentrion" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'" />
         </el-form-item>
  </el-form>
      <!-- 规格设置弹窗 -->
      <span slot="footer" class="dialog-footer">
      <el-button @click="initialize" >取消</el-button>
        <el-button :loading="sehe" @click="onaddmerchandiseinDate" type="primary">上架</el-button>
      </span>
    
    </el-dialog>
  </div>
</template>

<script>

import md5 from 'js-md5';
import getBrowserInfo from '../../utils/BrowserName'
import { uuid } from 'vue-uuid';
// 引入拖动列表组件

import  Editor  from '../../components/RichText.vue';
import time from "@/utils/time";
import {insertGoods,searchBusinessInfoByUserId,updateGoodStatus,searchBusinessInfoById,delGoodsSpecification,updateGoodsSpecification,insertGoodsSpecification,searchGoodsSpecificationByGoodsId,updateGoods,searchBusinessCategoryListForPC, searchGoodsList,delGoods,searchGoodsById,getCategoryList,getScondaryCategoryList } from "@/api";
export default {
  name: "UsesignList",
      components:{
Editor
  },
  data() {
    return {
      // 上架商品
      categoryIdco:"",
      longitude:"",
      latitude:"",
      businessName:"",
      addgoodsTypewhether:{
      goodsTypewhether1:false,
      goodsTypewhether2:false,
      },
      addgoodsDetailsJsons:[],
    handleDatet:{

    },
      addvideoForm:{
       Video:"",
      },
      sehe:false,
      slideshowInPhotoList:[],
        slideshowInPhotoindexList:[],
  addslideshowInPhotoUrl:"",
  addslideshowVisiblePhoto:false,
      revampGoodsReadUrl:"",
      revampGoodsReadstust:false,
      searchBusinessCategoryListtion:{},
      addmerchandiseDate:{
        goodsDetailsJson:{}
      },
      addmerchandiset:false,
      distributionType:"",
      businessId:"",
      businessIdst:"",
// 编辑商品
      onaddloding:false,
      VideoJon:"",
      // 商品橱窗视频
    kiar:false,
      videoForm:{
        videoUploadId:"",
        Video:"",
      },
      videoFlag:false,
      videoUploadPercent:0,
      headers:{
// equipmentIdentification:uuid.v1(),
// clientId:window.localStorage.getItem('clientId'),
// equipmentType:getBrowserInfo()[0],
// equipmentName:getBrowserInfo()[0].split("/")[0],
// requestTime: `${new Date().getTime()}`,
// sign:md5(`${uuid.v1()}#${getBrowserInfo()[0].split("/")[0]}#${getBrowserInfo()[0]}#${md5(uuid.v1().slice(7,26))}#${ parseInt(new Date().getTime()/1000/180)}`),
// Authorization:window.sessionStorage.getItem('accessToken'),
// testToken:"2gq72h2qrbhx256y0167uf5wd64ls55u",
// BG_DEBUG:0
      },
      goodsTypewhether:{
goodsTypewhether1:false,
goodsTypewhether2:false,
      },
      barginStatus:false,
      redactpecification:false,
      redactspecificationDate:{},
      goodsId:"",
      // 添加商品规格弹窗
      addspecificationDate:{
goodsId:""
      },
      addspecification:false,
      // 商品规格弹窗
      specification:false,
       specificationDate:[],
      handleDate:[],
      // 记录轮播图id
      rotationImgsid:[],
      // 上传轮播图
  
      rotationImgsJson:[],
      // 商品阅览图
      previewUrl:"",
      // 规则描述
      obj:{},
      goodsDetailsJsons:[],
      goodsDetailsJson:[],
      ImgsJson:[],
      content:"",
      searchBusinessCategoryList:[],
       addmerchandise:false,
      dialogimg:false,
      dialogimgsrc:"",
      gridData: [],
      // 弹窗页面设置
      setType: 0,
      loading: false,
      // 新建区域弹窗
      innerVisible: false,
      form: {},
      goodsdate:[],
      fileList: [],
      outerVisible: false,
      radio: "1",
      commodityStatus: [],
     
       merchantStatusList: [
        {
          value: 0,
          label: "上架",
        },
        {
          value: 1,
          label: "下架",
        },
      ],
    getValr:"",
    getVal:"",
      // 查找商品列表参数对象
      queryInfo: {
        page: 1,
        size: 10,
     cityCode:"",
     order:"desc"
      },
      gridDataC:[],
      time: "",

      total: 0,
      tableData: [
      ],
      categorydate:[],
      slideshowVisiblePhoto:false,
      slideshowInPhotoUrl:"",
      logisticsType:false,
      isMerchant:false,
      videoPreviewImgUrl:"",
      addvideoPreviewImgUrl:"",
      addslideshowVisiblePhotovideo:"",
addslideshowInPhotoUrlvideo:false,
goodsdatevideoPreviewImgUrl:"",
revampGoodsReadUrlvideo:"",
revampGoodsReadstustvideo:false
    };
    
  },
  created() {
    window.sessionStorage.setItem('style', '')
    if(window.sessionStorage.getItem('userIdentity')==1){
      this.isMerchant = window.sessionStorage.getItem("userIdentity")==1
      this.searchBusinessInfoByUser(window.sessionStorage.getItem('userId'))
      
    }else{
       this.getGoodsList();
    }
  },
  methods: {
  
    onaddvideoForm(){
this.addvideoForm.Video=''
this.addvideoForm.videoUploadId=''
    },
    onvideoPreviewImgUrl(){
this.addvideoPreviewImgUrl=''
    },
    onvideoForm(){
this.videoForm.Video=''
this.videoForm.videoUploadId=''
    },
       //删除商品阅览图
    handleRemoveGoodsReadvideo(){
      this.goodsdatevideoPreviewImgUrl=""
    },
    // 查看商品阅览图
     revampGoodsReadvideo(){
           this.revampGoodsReadUrlvideo=this.goodsdatevideoPreviewImgUrl
           this.revampGoodsReadstustvideo=true
       },
    // 上传视频封面
   async upGoodsReadvideo(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",6)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.goodsdatevideoPreviewImgUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    // 上架商品
   async  onaddmerchandiseinDate(){
   
if(!this.addmerchandiseDate.goodsName)return this.$message.error("商品名称为空");
  if(!this.addmerchandiseDate.previewUrl)return this.$message.error("商品阅览图为空");
  if(this.slideshowInPhotoList.length==0)return this.$message.error("商品橱窗图为空");
    if(this.addvideoForm.Video){
  if(!this.videoPreviewImgUrl){
    return this.$message.error("商品橱窗图封面未上传");
  }
}
if(this.videoPreviewImgUrl){
if(!this.addvideoForm.Video){
  return this.$message.error("商品橱窗图视频未上传");
}
}
  if(!this.addmerchandiseDate.businessCategoryId)return this.$message.error("商品分类未选择")
  if(!this.addmerchandiseDate.goodsBeginTime||!this.addmerchandiseDate.goodsEndingTime)return this.$message.error("售卖周期未填写完整")
  if(!this.addmerchandiseDate.effectDays)return this.$message.error("购买有效天数")
  if(!this.addmerchandiseDate.goodsPrice)return this.$message.error("未填写商品价格")
  if(!this.addmerchandiseDate.scribePrice)return this.$message.error("未填划线价格")
  if(this.addmerchandiseDate.scribePrice-0<this.addmerchandiseDate.goodsPrice-0)return this.$message.error("成交价不得大于划线价");
  if(!this.addmerchandiseDate.stockNum)return this.$message.error("未填写库存量")
  if(!this.getValr)return this.$message.error("未填写使用规则")
  if(!this.contentrion)return this.$message.error("商品详情未填写")
          if(this.addvideoForm.Video){
      this.slideshowInPhotoList.unshift(this.videoPreviewImgUrl)
this.slideshowInPhotoList.unshift(this.addvideoForm.Video)
           }
           
     this.addmerchandiseDate.rotationImgsJson=this.slideshowInPhotoList.join(',')
     this.addmerchandiseDate.useRuleJson= `  <html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.getValr}</body></html> `
this.addmerchandiseDate.goodsDetails= `  <html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.contentrion}</body></html> `
this.addmerchandiseDate.goodsDetailsJson=""
// this.addgoodsDetailsJsons.forEach((element,i) => {
//     const key="t"+i
//     const value=element
//       this.addmerchandiseDate.goodsDetailsJson[key]=value.t1
// })
// this.addmerchandiseDate.goodsDetailsJson=JSON.stringify(this.addmerchandiseDate.goodsDetailsJson)
    if(this.distributionType==6){
    if(this.addgoodsTypewhether.goodsTypewhether1&&this.addgoodsTypewhether.goodsTypewhether2){
this.addmerchandiseDate.goodsType=6
    }else if(this.addgoodsTypewhether.goodsTypewhether1){
this.addmerchandiseDate.goodsType=1
    }else{
      this.addmerchandiseDate.goodsType=3
    }
    } else if(this.distributionType==7){
    if(this.addgoodsTypewhether.goodsTypewhether1&&this.addgoodsTypewhether.goodsTypewhether2){
this.addmerchandiseDate.goodsType=7
    }else if(this.addgoodsTypewhether.goodsTypewhether1){
this.addmerchandiseDate.goodsType=4
    }else{
      this.addmerchandiseDate.goodsType=5
    }
    }else{
    this.addmerchandiseDate.goodsType= this.distributionType
    }
this.addmerchandiseDate.businessId=this.businessId
this.addmerchandiseDate.goodsStatus=0
this.addmerchandiseDate.categoryId=this.categoryIdco
    //   this.addmerchandiseDate.longitude=this.longitude
    //  this.addmerchandiseDate.latitude=this.latitude
 this.addmerchandiseDate.addressJson= this.addressJson
    this.sehe=true
  this.addmerchandiseDate.businessName=this.businessName
 const { data: res } = await insertGoods(this.addmerchandiseDate);
  if(res.code!==200)return this.$message.error(res)
  this.sehe=false

    const Jsonc={
  dealPrice:res.body.goodsPrice,
  goodsId: res.body.id,
  scribePrice: res.body.scribePrice,
  specificationName: "默认",
  stockNum:res.body.stockNum
  }
  const { data: date } = await insertGoodsSpecification(Jsonc); 
   if(date.code!==200)return this.$message.error(res.msg)
 this.initialize()
 
    },
    initialize(){
this.addmerchandiseDate={
  goodsDetailsJson:{},
}
this.getValr=""
this.slideshowInPhotoList=[]
this.slideshowInPhotoindexList=[]
this.childoin=""
this.contentrion=""
this.addvideoForm={
  Video:""
}
this.videoPreviewImgUrl=""
this.addgoodsDetailsJsons={}
 
this.getGoodsList();
this.sehe=false
this.addmerchandiset=false

    },
       // 删除描述
       addremogoodsDetailsJson(e){
this.addgoodsDetailsJsons.splice(e,1)

    },
    //添加描述
    onaddgoodsDeta(){
const obj={
  "t1":""
}
 this.addgoodsDetailsJsons.push(obj)
    },
        goodsTimer(){
 this.addmerchandiseDate.goodsBeginTime = this.handleDatet[0]
      this.addmerchandiseDate.goodsEndingTime = this.handleDatet[1]
    },
        // 显示进度
adduploadVideoProcess(event, file, fileList){
    this.videoFlag = true;
    this.videoUploadPercent = parseFloat(file.percentage.toFixed(0))
},
// 视频上传成功
async addhandleVideoSuccess(file) {    
     const formData = new FormData()
       formData.append("file", file.file);
      const { data: res } = await this.$http.post(`/api/platform/uploadVideo`,formData);
    if(res.code == 200){
      //  this.images.push(res.result.url)
        this.addvideoForm.Video = res.body;
    }else{
        this.$message.error('视频上传失败，请重新上传！');
    }
    },
     addvideoSaveToUrl(event) {
  
      this.video = event.raw;
    },
    // 验证视频大小
 
 addbeforeUploadVideo(file) {
  
    const isLt10M = file.size / 1024 / 1024  < 20;
    if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式');
        
        return false;
    }
    if (!isLt10M) {
        this.$message.error('上传视频大小不能超过20MB哦!');
        return false;
    }
    },
          // 删除轮播图
       addslideshowPictureCardRemoveInPhotoList(file){
this.slideshowInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.slideshowInPhotoindexList.splice(i,1)
 this.slideshowInPhotoList.splice(i,1)
 } 
});
    },
    // 查看轮播图
    addslideshowReversesideofidcard(file){
this.slideshowInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.addslideshowInPhotoUrl= this.slideshowInPhotoList[i]
   this.addslideshowVisiblePhoto=true
 }
 });
    },
          // 删除轮播图
       addslideshowPictureCardRemoveInPhotoListvideo(){
this.videoPreviewImgUrl=""

    },
    // 查看轮播图
    addslideshowReversesideofidcardvideo(file){

   this.addslideshowInPhotoUrlvideo= true
   this.addslideshowVisiblePhotovideo=this.videoPreviewImgUrl

    },
   async upaddslidevideo(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",6)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");

     this.videoPreviewImgUrl=res.body[0]
     
      this.$message.success("上传成功");
    },
    // 上传轮播图
   async upaddslideshow(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
     this.slideshowInPhotoindexList.push(file.file.uid)
     this.slideshowInPhotoList.push(res.body[0].split("?")[0])
      this.$message.success("上传成功");
    },
       //删除商品阅览图
    addhandleRemoveGoodsRead(){
      this.addmerchandiseDate.previewUrl=""
    },
    // 查看商品阅览图
     addrevampGoodsRead(){
           this.revampGoodsReadUrl=this.addmerchandiseDate.previewUrl
           this.revampGoodsReadstust=true
       },
    // 上传商品阅览图
   async upaddGoodsRead(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.addmerchandiseDate.previewUrl= res.body[0].split("?")[0];
     
      this.$message.success("上传成功");
    },
    // 获取分类
      async  onclassifyVisible(){
   const { data: res } = await   searchBusinessCategoryListForPC({businessId:this.businessId,order:"asc"});
   if (res.code !== 200) return this.$message.error("获取店内信息失败请稍后重试");
   this.handleDatet=undefined
   this.searchBusinessCategoryListtion=res.body
  this.addmerchandiset = true
      
    },
    // 查询商家id
  async  searchBusinessInfoByUser(id){
    const { data: res } = await searchBusinessInfoByUserId({userId:id}); 
      this.queryInfo.businessId=res.body.id
    this.distributionType =res.body.logisticsType
     this.businessId=res.body.id
     this.businessName=res.body.businessName
     this.latitude=res.body.latitude
     this.longitude=res.body.longitude
     this.categoryIdco=res.body.categoryId
     this.addressJson=res.body.businessAddressJson
    this.addmerchandiseDate.categoryId= res.body.categoryId
    this.addmerchandiseDate.primaryCategoryId=res.body.primaryCategoryId
      window.sessionStorage.setItem('businessId',res.id)
       this.getGoodsList();
    },
    // 商品上下架
  async updateGoodStatust(ids,Status){
     const formData = new FormData()
       formData.append("id", ids);
        formData.append("goodsStatus", Status);
         const { data: res } = await this.$http.post(`/api/goods/updateGoodStatus`,formData);
if (res.code !== 200) return this.$message.error("商品状态更新失败请稍后重试");
 this.$message.success("商品状态更新成功");
  this.getGoodsList();
    },
    isBargainingHandel(){
if(this.barginStatus){
  this.goodsdate.distributionStatus=1
}else{
   this.goodsdate.distributionStatus=0
}
    },
     // 删除视频
     onspx(){
       this.videoForm.Video=""
      this.videoFlag=true,
     this.videoUploadPercent=0
   
      },
    // 显示进度

uploadVideoProcess(event, file, fileList){
    this.videoFlag = true;
    this.videoUploadPercent = parseFloat(file.percentage.toFixed(0))
},
// 视频上传成功
async handleVideoSuccess(file) {    
  const formData = new FormData()
       formData.append("file", file.file);
      const { data: res } = await this.$http.post(`/api/platform/uploadVideo`,formData);
    if(res.code == 200){
      //  this.images.push(res.result.url)
        this.videoForm.Video = res.body;
      
   
    }else{
        this.$message.error('视频上传失败，请重新上传！');
    }
    },
     videoSaveToUrl(event) {
  
      this.video = event.raw;
    },
    // 验证视频大小
 
beforeUploadVideo(file) {
    const isLt10M = file.size / 1024 / 1024  < 20;
    if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式');
        
        return false;
    }
    if (!isLt10M) {
        this.$message.error('上传视频大小不能超过20MB哦!');
        return false;
    }
    },
    // 删除规格
   async delGoodsSpecifi(id,goodsId){
if(this.specificationDate.length==1){
return this.$message.error("规格不能为空");
}
      const formData = new FormData()
  formData.append("ids", id);
       const { data: res } = await delGoodsSpecification (formData);
      if (res.code !== 200) return this.$message.error("删除规格失败请稍后重试");
       this.$message.success("删除规格成功");
       this.specificationVisible(goodsId)
    },
    // 点击确认编辑
      async redactspecificationDater(){
      if(!this.redactspecificationDate.specificationName)return  this.$message.error("请输入规格名称");
       if(!this.redactspecificationDate.dealPrice)return  this.$message.error("请输入成交价格");
        if(!this.redactspecificationDate.scribePrice)return  this.$message.error("请输入划线价格");
        if(this.redactspecificationDate.scribePrice-0<this.redactspecificationDate.dealPrice-0)return  this.$message.error("成交价不得大于划线价");
         if(!this.redactspecificationDate.stockNum)return  this.$message.error("请输入库存量");
       const { data: res } = await updateGoodsSpecification (this.redactspecificationDate); 
       if (res.code !== 200) return this.$message.error("添加规格失败请稍后重试");
          this.$message.success("添加规格成功");
      this.specificationVisible(this.redactspecificationDate.goodsId)
     this.redactpecification=false
    },
    // 编辑规格
    redactspecification(row){
    this.redactspecificationDate={
    dealPrice:row.dealPrice,
    goodsId: row.goodsId,
    id: row.id,
    scribePrice: row.scribePrice,
    specificationName: row.specificationName,
    stockNum: row.stockNum
}  
  
this.redactpecification=true
    },
    // 确认添加新规格
    async addspecificationDater(){
    this.addspecificationDate.goodsId= this.goodsId
      if(!this.addspecificationDate.specificationName)return  this.$message.error("请输入规格名称");
       if(!this.addspecificationDate.dealPrice)return  this.$message.error("请输入成交价格");
        if(!this.addspecificationDate.scribePrice)return  this.$message.error("请输入划线价格");
        if(this.addspecificationDate.scribePrice-0<this.addspecificationDate.dealPrice-0)return  this.$message.error("成交价不得大于划线价");
         if(!this.addspecificationDate.stockNum)return  this.$message.error("请输入库存量");
       const { data: res } = await insertGoodsSpecification (this.addspecificationDate); 
       if (res.code !== 200) return this.$message.error("添加规格失败请稍后重试");
       this.$message.success("添加规格成功");
     this.addspecificationDate={}
     this.specificationVisible(this.goodsId)
     this.addspecification=false
    },
    // 点击打开规格弹窗
   async specificationVisible(id){
    const { data: res } = await searchGoodsSpecificationByGoodsId ({goodsId:id});  
     if (res.code !== 200) return this.$message.error("获取规格信息失败请稍后重试");
     
    this.specificationDate=res.body
    this.goodsId=id
this.specification=true
    },
    // 保存修改
   async  onaddmerchandiseDate(){
  if(this.logisticsType==6||this.logisticsType==7){
    if(!this.goodsTypewhether.goodsTypewhether1&&!this.goodsTypewhether.goodsTypewhether2) return this.$message.error("物流未选择")
  }
  if(this.videoForm.Video){
    if(!this.goodsdatevideoPreviewImgUrl&&!this.addvideoPreviewImgUrl){
      return this.$message.error('商品视频封面为上传');
    }
  }
  if(this.goodsdatevideoPreviewImgUrl||this.addvideoPreviewImgUrl){
     if(!this.videoForm.Video){
       return this.$message.error('商品橱窗图视频未上传');
     }
  }
if(!this.goodsdate.goodsName) return this.$message.error('未填写商品名称');
if(!this.goodsdate.businessCategoryId) return this.$message.error('未选择商品分类');
if(!this.goodsdate.goodsBeginTime||!this.goodsdate.goodsEndingTime) return this.$message.error('未选择商品日期');
if(!this.goodsdate.effectDays) return this.$message.error('未选购买有效期天数');
// if(this.goodsDetailsJsons.length==0) return this.$message.error('未选填写商品描述');
     if(this.goodsdate.scribePrice-0<this.goodsdate.goodsPrice-0)return this.$message.error("成交价不得大于划线价");
 if(!  this.goodsdate.goodsPrice)return this.$message.error("未填写商品价格")
  if(! this.goodsdate.scribePrice)return this.$message.error("未填写划线价格")
  if(! this.goodsdate.stockNum)return this.$message.error("未填写库存量")
 
   if(!this.getVal)return this.$message.error("未填写使用规则")
    if(!this.contentc  )return this.$message.error("商品详情未填写")   
    if(this.rotationImgsJson.length>0){
   
      this.goodsdate.rotationImgsJson=this.rotationImgsJson.join(',')
    }else{
    this.goodsdate.rotationImgsJson = this.ImgsJson.join(',')
    }
    if(this.goodsdatevideoPreviewImgUrl){
   this.goodsdate.rotationImgsJson= this.goodsdate.rotationImgsJson.split(",") 
     this.goodsdate.rotationImgsJson.unshift(this.goodsdatevideoPreviewImgUrl) 
     this.goodsdate.rotationImgsJson=this.goodsdate.rotationImgsJson.join(',')
    }else {
      if(this.addvideoPreviewImgUrl){
     this.goodsdate.rotationImgsJson= this.goodsdate.rotationImgsJson.split(",") 
this.goodsdate.rotationImgsJson.unshift(this.addvideoPreviewImgUrl) 
this.goodsdate.rotationImgsJson=this.goodsdate.rotationImgsJson.join(',')
      }
       }
        if(this.videoForm.Video){
           this.goodsdate.rotationImgsJson= this.goodsdate.rotationImgsJson.split(",") 
     this.goodsdate.rotationImgsJson.unshift(this.videoForm.Video)
    this.goodsdate.rotationImgsJson=this.goodsdate.rotationImgsJson.join(',')
           }
         
//     this.goodsDetailsJsons.forEach((element,i) => {
//     const key="t"+i
//     const value=element
//       this.goodsdate.goodsDetailsJson[key]=value.t1
// })
  // this.goodsdate.goodsDetailsJson=JSON.stringify(this.goodsdate.goodsDetailsJson)
   this.goodsdate.useRuleJson= `  <html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.getVal}</body></html> `
this.goodsdate.goodsDetails= `  <html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.contentc}</body></html> `
this.goodsdate.goodsDetailsJson=""
   if(this.logisticsType==6){
    if(this.goodsTypewhether.goodsTypewhether1&&this.goodsTypewhether.goodsTypewhether2){
this.goodsdate.goodsType=6
    }else if(this.goodsTypewhether.goodsTypewhether1){
this.goodsdate.goodsType=1
    }else{
      this.goodsdate.goodsType=3
    }
    } else if(this.logisticsType==7){
    if(this.goodsTypewhether.goodsTypewhether1&&this.goodsTypewhether.goodsTypewhether2){
this.goodsdate.goodsType=7
    }else if(this.goodsTypewhether.goodsTypewhether1){
this.goodsdate.goodsType=4
    }else{
      this.goodsdate.goodsType=5
    }
    }
    this.onaddloding=true
    // this.goodsdate.rotationImgsJson= "http://oss.fuhangbeiyue.com/video/322b7132bf61498db346de31b0c698ab_1655689467469.mp4"
   const { data: res } = await updateGoods (this.goodsdate);
          if (res.code !== 200) return this.$message.error("编辑失败稍后重试");
           
          this.$message.success("编辑成功");
          this.addvideoPreviewImgUrl=""
          this.getVal=""
         this.handleDatet={}
        this.addmerchandise=false
           this.onaddloding=false
           const { data: date } = await   searchGoodsSpecificationByGoodsId ({goodsId:this.goodsdate.id});
          for (let i = 0; i < date.body.length; i++) {
            if(date.body[i].specificationName=="默认"){
            
              this.obj={
                dealPrice:this.goodsdate.goodsPrice,
                goodsId:this.goodsdate.id,
                id:date.body[i].id,
                scribePrice:this.goodsdate.scribePrice,
                specificationName:"默认",
                stockNum:this.goodsdate.stockNum
              }
            
            }
          }
           this.contentpoui() 
        this.getGoodsList();
     
    },
  async  contentpoui(){
 const { data: data } = await updateGoodsSpecification (this.obj); 

 this.obj={}
    },
    beforeAvatarUploadpoc(file){
    if (!(file.type === 'image/jpeg' || file.type ==='image/png')){
  this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
   return false
    }
   	if ( !(file.size / 1024 / 1024 < 5)) {
			this.$message.error('上传图片大小不能超过 5MB!');
			 return false
		}
 return this.imgcheckedpoc(file)
   
    },
      // 上传图片验证
    beforeAvatarUpload(file){
    if (!(file.type === 'image/jpeg' || file.type ==='image/png')){
  this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
   return false
    }
   	if ( !(file.size / 1024 / 1024 < 5)) {
			this.$message.error('上传图片大小不能超过 5MB!');
			 return false
		}
 return this.imgchecked(file)
   
    },
     imgchecked(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file) // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          let img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (!(480<=img.width<=1242&&img.height<=1920)) {
             this.$message.error('480px≤图片宽度≤1242px(图片宽度建议上传750)，0<图片高度≤1920px');
             reject()
            } else {
              resolve(true)
            }
          }
        }
      })
        },
     imgcheckedpoc(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file) // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          let img = new Image()
          img.src = reader.result
          img.onload = () => {
               if (0.8<=(img.width/img.height)&&(img.width/img.height)<=1.5) {
               resolve(true)
            } else {
                 this.$message.error('图片比例宽高1比1左右图片');
             reject()
            }
          }
        }
      })
        },
    // 删除轮播图
       slideshowPictureCardRemoveInPhotoList(file){
this.rotationImgsid.forEach((element,i) => {
 if(element==file.uid){
   this.rotationImgsJson.splice(i,1)
 this.rotationImgsid.splice(i,1)
 } 
});
    },
     // 查看轮播图
    slideshowReversesideofidcard(file){
this.rotationImgsid.forEach((element,i) => {
 if(element==file.uid){
   this.slideshowInPhotoUrl= this.rotationImgsJson[i]
   this.slideshowVisiblePhoto=true
 }
 });
    },
// 上传轮播图
 async upslideshow(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
     this.rotationImgsid.push(file.file.uid)
     this.rotationImgsJson.push(res.body[0].split("?")[0])
      this.$message.success("上传成功");
    },
    // 删除描述
       remogoodsDetailsJson(e){
this.goodsDetailsJsons.splice(e,1)

    },
    //添加描述
    ongoodsDeta(){
const obj={
  "t1":""
}
 this.goodsDetailsJsons.push(obj)
    },
    goodsTime(){
 this.goodsdate.goodsBeginTime = this.handleDate[0]
      this.goodsdate.goodsEndingTime = this.handleDate[1]
    },
    getGoodsshopName(){
this.queryInfo.page = 1;
this.getGoodsList();
    },
    goodsStatus(){
this.queryInfo.page=1
this.getGoodsList();
    },
     //删除商品阅览图
    handleRemoveGoodsRead(){
      this.goodsdate.previewUrl=""
    },
    // 查看商品阅览图
     revampGoodsRead(){
           this.revampGoodsReadUrl=this.goodsdate.previewUrl
           this.revampGoodsReadstust=true
       },
    // 上传商品阅览图
   async upGoodsRead(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.goodsdate.previewUrl= res.body[0].split("?")[0];
     
      this.$message.success("上传成功");
    },
       dialogimgs(data){
this.dialogimgsrc=data
this.dialogimg=true
    },
    // 选择一级分类
   async  getSelectGoodsListStatus(date){
      this.queryInfo.page = 1;
   this.queryInfo.goodsCategoryId=date
    this.getGoodsList();
const { data: res } = await getScondaryCategoryList({id:date})
    this.loading=false
this.categorydate=res.body
    },
    // 选择市
  async oncity(date){
    this.queryInfo.page = 1;
if(date.code){
this.queryInfo.cityCode= date.code.substring(0,4)
this.queryInfo.cityName=date.value
const { data: res } = await getCategoryList({code:this.queryInfo.cityCode})
this.commodityStatus=res.body
}else{
  this.queryInfo.cityCode=""
this.queryInfo.cityName=""
this.commodityStatus=[]
this.categorydate=[]
this.queryInfo.goodsSubcategoryId=""
this.queryInfo.goodsCategoryId=""
}
    
  this.getGoodsList();
    },
    // 点击获取商品详情
   async searchGoodsById(ids,lat,long,businessIds){
var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
const { data: res } = await searchGoodsById({id:ids,latitude:lat,longitude:long})
this.goodsdate=res.body;

this.goodsDetailsJson=[]
this.goodsDetailsJsons=[]
if(this.goodsdate.goodsDetailsJson){
this.goodsdate.goodsDetailsJson=JSON.parse(this.goodsdate.goodsDetailsJson)

for (const key in this.goodsdate.goodsDetailsJson) {
 this.goodsDetailsJson.push(this.goodsdate.goodsDetailsJson[key])
}
this.goodsDetailsJson.forEach(element => {
  const obj={"t1":element} 
   this.goodsDetailsJsons.push(obj)
});
}else{
  this.goodsDetailsJsons=[]
}

this.handleDate=[time(this.goodsdate.goodsBeginTime),time(this.goodsdate.goodsEndingTime)]

// this.queryInfo.time = dataFunction(this.time);
// goodsBeginTime
// goodsEndingTime
   const { data: date } = await searchBusinessCategoryListForPC ({businessId:businessIds,order:"asc"});
    if (res.code !== 200) return this.$message.error("获取商家店内分类错误");
    this.searchBusinessCategoryList=date.body
this.previewUrl=this.goodsdate.previewUrl
this.getVal=rx.exec(this.goodsdate.useRuleJson)[1]
this.contentc= rx.exec(this.goodsdate.goodsDetails)[1]

this.rotationImgsJson=[]
this.goodsDetailsJson=[]
this.addvideoPreviewImgUrl=""
this.rotationImgsid=[]
 this.videoForm.Video=""
this.ImgsJson=this.goodsdate.rotationImgsJson.split(",") 
if(this.ImgsJson[0].split('.')[3]=="mp4"){
 this.videoForm.Video=this.ImgsJson.splice(0,1)[0]
   this.addvideoPreviewImgUrl=this.ImgsJson.splice(0,1)[0]
}
  this.goodsTypewhether.goodsTypewhether1=false
  this.goodsTypewhether.goodsTypewhether2=false
const { data: data } = await searchBusinessInfoById({id:businessIds});
this.logisticsType=data.body.logisticsType
if(this.goodsdate.goodsType==6||this.goodsdate.goodsType==7){
  this.goodsTypewhether.goodsTypewhether1=true
  this.goodsTypewhether.goodsTypewhether2=true
}else if(this.goodsdate.goodsType==1||this.goodsdate.goodsType==4){
   this.goodsTypewhether.goodsTypewhether1=true
}else if(this.goodsdate.goodsType==3||this.goodsdate.goodsType==5){
   this.goodsTypewhether.goodsTypewhether2=true
}
this.sehe=false
this.addmerchandise=true
    },
    // 删除商品
    async deleter(id){
  const formData = new FormData()
  formData.append("ids", id);
  const { data: res } = await delGoods(formData)
  if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
  this.$message.success("删除成功");
     this.loading=false
   this.getGoodsList();
},
onprovince(date){
  this.queryInfo.page=1
if(date.value=="省"){
this.queryInfo.provinceName=""
}else{
this.queryInfo.provinceName=date.value
}

  this.getGoodsList();
},
    /*
     * 页面加载请求所有的商品数据
     * 默认请求第一页 十条数据
     * */
    async getGoodsList() {
        const { data: res } = await searchGoodsList(this.queryInfo);
        if (res.code !== 200) return this.$message.error("商品列表获取错误");
       if(res.body){
  
         this.tableData = res.body.list;
            this.tableData.forEach((element,i) => {
            this.tableData[i].goodsEndingTime=time(this.tableData[i].goodsEndingTime)
          if(element.addressJson){
  
       
        this.tableData[i].addressJson=JSON.parse(element.addressJson)
          }
        });
  
         }else{
             this.tableData =[]
         }
     
        
        this.total = res.body.total;
       this.loading=false
    },

    handleCurrentChange(data) {
      this.queryInfo.page = data;
          this.loading=false
      this.getGoodsList();
      
    },

    handleSizeChange(newSize) {
      this.queryInfo.page = 1;
      this.queryInfo.size = newSize;
           this.loading=false
      this.getGoodsList();
     
    },
    // 模糊搜索
    getGoodsListkow(){
this.queryInfo.page = 1;
    this.loading=false
this.getGoodsList();

    },


    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
  watch:{
    redactpecification(e){
   if(!e){
     this.handleDatet={}
   }
    },
    addmerchandiset(addmerchandiset){
 if(addmerchandiset){
       window.sessionStorage.setItem('style', true)
    }else{
       window.sessionStorage.setItem('style', '')
    }
    }
   
    
  }
};
</script>

<style scoped lang="scss">
.mr15 {
  margin-right: 15px;
}

p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}
.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.el-dialog__body{
  padding-left:20px ;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}
.el-tooltip{
  margin-left: -7px;
}
.v-distpicker ::v-deep select {
  height: 29px !important;

  padding-top: 0.4rem;
  font-size: 12px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mr15 {
  margin-right: 15px;
}
.mdr5 ::v-deep .el-upload-list{
  display: none;
}
</style>